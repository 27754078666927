/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import SectionBanner from "views/index-sections/SectionBanner";
import SectionNews from "views/examples/newsList2";
import SectionDirector from "views/index-sections/SectionDirector";
import SectionAbout from "views/index-sections/SectionAbout";
import SectionCounter from "views/index-sections/SectionCounter";
// import SectionCIO from "views/index-sections/SectionCio";
import SectionSatisfied from "views/index-sections/SectionSatisfied"
import preSident from "../../assets/img/faces/p1.jpg";
import vicePresident1 from "../../assets/img/faces/non.png";
import vicePresident2 from "../../assets/img/faces/p7.jpg";
import vicePresident3 from "../../assets/img/faces/BOOSABOK_P.png";
import planDirector from "../../assets/img/faces/11.png";
import { Link } from "react-router-dom";
import configData from "../../config.json";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import SectionRating from "views/index-sections/SectionRating";
import SectionContract from "views/index-sections/SectionContract";
import SectionQrcodeGener from "views/index-sections/SectionQrcodegener";
import CookieConsent from "react-cookie-consent";
import ReactGa from "react-ga";
import {Button,Card,CardBody,CardFooter,CardTitle,Container,Row,Col,Modal,} from "reactstrap";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "assets/css/listgroup-noborder.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import SectionBB from "views/index-sections/SectionBB-go-th";

var cardStyle = {
  display: "block",
  transitionDuration: "0.3s",
  height: "5vw",
};

function LandingPage() {
 
  const d = new Date();
  useEffect(() => {
    getData();
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ดึงข้อมูลจาก query String
  const { search } = useLocation();
  const displayParams = new URLSearchParams(search);
  const dispID = displayParams.get("i");
  const redirectID = displayParams.get("r");

  document.documentElement.classList.remove("nav-open");

  const [shareId, setShareId] = useState("");
  const [detail, setDetail] = useState("");
  const [caption, setCaption] = useState("");
  const [file, setFile] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [cookiesModal, setcookiesModal] = useState(false);

  const [qrValue, setQrValue] = useState("");
  const [txtToclipboard, settxtToclipboard] = useState(false);

    const toggleModal2 = () => {
      setModal2(!modal2);
    };
    const toggleCookiesModal = () => {
      setcookiesModal(!cookiesModal);
    };

  const toggleModal = () => {
    setModal(!modal);
        settxtToclipboard(false);
  };

  const showModal = (dataD) => {
   
    if (!dataD.ErrMessage) {
      setShareId(dataD.content_id);
      setCaption(dataD.ct_caption);
      setDetail(dataD.ct_detail);
      setFile(dataD.ct_file);
      setThumbnail(dataD.ct_thumbnail);
    } else {
      setShareId("notFound");
      setCaption("ไม่พบข้อมูล");
      setDetail(
        "ข้อมูลอาจถูกลบหรือมีการแก้ไขกรุณาติดต่อเจ้าหน้าที่กองแผนงานที่แชร์ข้อมูลนี้"
      );
      setFile("");
      setThumbnail(null);
    }

     setQrValue(configData.WEB_BASE + "?i=" + dispID);
    toggleModal();
  };

  const getData = () => {
    if (dispID != null) {
      axios
        .get(configData.API_URL + "/w3_api/api/post/read_single.php", {
          params: {
            id: dispID,
          },
        })
        .then((Response) => showModal(Response.data));
    }

    // จะทำ Redirect แต่ยังไม่ได้ทำ
    if (redirectID != null) {
      axios
        .get(configData.API_URL + "/w3_api/api/post/read_single.php", {
          params: {
            id: redirectID,
          },
        })
        .then((Response) => showModal(Response.data));
    }
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen-download");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${shareId}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    toggleModal2();
  };

  return (
    <>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto mb-0" md="12">
                <h2 className="title text-primary">ข่าว / เอกสาร</h2>
                <p className="mt-2 mb-4">ข่าวและเอกสารเผยแพร่ของกองแผนงาน</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto mb-0" md="12">
                <SectionNews />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col className="mt-5" md="12" lg="12">
                <div class="mb-5">
                  <Link to="/data-center">
                    <h2>ศูนย์ข้อมูลกองแผนงาน</h2>
                  </Link>
                  <p className="mt-2">
                    ท่านสามารถดาวน์โหลด <Link to="/login">ค้นหา</Link> เอกสาร
                    แบบฟอร์ม ประกาศ ต่าง ๆ ได้จากที่นี่
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <Link to="/data-center">
                      <i className="nc-icon nc-bank" />
                    </Link>
                  </div>
                  <div className="description">
                    <h4 className="info-title">ระดับหน่วยงาน</h4>
                    <p className="description">
                      จำแนกข้อมูลตามระดับหน่วยงาน เช่น ระดับมหาวิทยาลัย
                      ระดับสำนักงานอธิการบดี ฯลฯ
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <Link to="/data-center2">
                      <i className="nc-icon nc-single-copy-04" />
                    </Link>
                  </div>
                  <div className="description">
                    <h4 className="info-title">ประเภทเอกสาร</h4>
                    <p>
                      จำแนกข้อมูลตามประเภทเอกสาร เช่น ระเบียบ ประกาศ แบบฟอร์ม ฯ
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <Link to="/data-center3">
                      <i className="nc-icon nc-tag-content" />
                    </Link>
                  </div>
                  <div className="description">
                    <h4 className="info-title">หัวข้องาน</h4>
                    <p>
                      จำแนกเอกสารตามหัวข้องาน เช่น การจัดทำงบประมาณประจำปี
                      การจัดหาระบบคอมพิวเตอร์ การจัดทำแผนต่าง ๆ{" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <SectionAbout />
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">ผู้บริหารและบุคลากร</h2>
            <Row>
              <Col md="12">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={preSident} />
                    </a>
                  </div>
                  <CardBody style={cardStyle}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h5">
                          ศาสตราจารย์ ดร.ภก.ธนะเศรษฐ์ <br /> ง้าวหิรัญพัฒน์
                        </CardTitle>
                        <h6 className="card-category">อธิการบดี</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center">
                    {/* <p className="card-description text-center">
                      SUTANANTA_W@SU.AC.TH
                    </p> */}
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={vicePresident1} />
                    </a>
                  </div>
                  <CardBody style={cardStyle}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h5">
                          ผู้ช่วยศาสตราจารย์ ดร.นนท์ คุณค้ำชู
                        </CardTitle>
                        <h6 className="card-category">
                          รองอธิการบดีฝ่ายวางแผนและพัฒนา
                        </h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center">
                    {/* <p className="card-description text-center">
                      LAOPOONPHAT_P@SU.AC.TH
                    </p> */}
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={vicePresident2} />
                    </a>
                  </div>
                  <CardBody style={cardStyle}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h5">อาจารย์พงศธร เวสสบุตร</CardTitle>
                        <h6 className="card-category">
                          ผู้ช่วยอธิการบดีฝ่ายวางแผนและพัฒนา
                        </h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center">
                    {/* <p className="card-description text-center">
                      YASRI_P@SU.AC.TH
                    </p> */}
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={vicePresident3} />
                    </a>
                  </div>
                  <CardBody style={cardStyle}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h5">อาจารย์ ดร.พิภู บุษบก</CardTitle>
                        <h6 className="card-category">
                          ผู้ช่วยอธิการบดีฝ่ายวางแผนและจัดการนโยบาย
                        </h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center">
                    {/* <p className="card-description text-center">
                      YASRI_P@SU.AC.TH
                    </p> */}
                  </CardFooter>
                </Card>
              </Col>
              <Col md="12">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={planDirector} />
                    </a>
                  </div>
                  <CardBody style={cardStyle}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h5">
                          นางสาวเสาวณีย์ คูพูลทรัพย์
                        </CardTitle>
                        <h6 className="card-category">ผู้อำนวยการกองแผนงาน</h6>
                      </div>
                    </a>
                  </CardBody>
                  <CardFooter className="text-center">
                    <p className="card-description text-center">
                      KUPULSUB_S@SU.AC.TH
                    </p>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Link to="/staff">
                  <Button className="btn-round" color="info">
                    ผู้บริหารและบุคลากรทั้งหมด
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>

        <SectionDirector />
        {/* <div className="section">
          <SectionCIO />
        </div> */}
        <div className="section section-dark-blue">
          <SectionQrcodeGener />
        </div>
        {/* <div className="section">
          <SectionBB />
        </div> */}

        <Row className="section section-su text-center">
          <Container>
            <h2 className="title">ลิงก์ (link) </h2>
            <p className="mt-2 mb-4">เว็บลิงก์ไประบบหรือเนื้อหาที่สำคัญ</p>
            <SectionBanner />
          </Container>
        </Row>

        <div className="section section-light">
          <SectionCounter />
        </div>
        <div>
          <SectionSatisfied />
        </div>
        <div className="section section-light">
          <SectionContract />
        </div>
      </div>
      <DemoFooter />
      <CookieConsent
        location="bottom"
        buttonText="ยอมรับ"
        declineButtonText="ไม่ยอมรับ"
        cookieName="Cookies_plan.su.ac.th_And_su-plan.org"
        enableDeclineButton
        style={{ background: "#2B373B" }}
        declineButtonStyle={{
          color: "#050505",
          background: "#7A7472",
          fontSize: "13px",
        }}
        buttonStyle={{
          color: "#050505",
          background: "#03fc0f",
          fontSize: "13px",
        }}
        expires={150}
        cookieValue={btoa(d.getTime().toString())}
        onAccept={() => ReactGa.initialize("G-47RQ2DKQ5D")}
      >
        เว็บไซต์นี้และระบบต่าง ๆ ภายใต้โดเมน su-plan.org มีการใช้คุกกี้
        (Cookies)
        ซึ่งจะช่วยให้คุณได้รับการบริการที่ดีและจะช่วยให้เราสามารถพัฒนาคุณภาพของเว็บไซต์ให้ดียิ่งขึ้น
        ด้วยการกด "ยอมรับ"
        <Button
          className="btn-link"
          color="info"
          type="button"
          onClick={() => toggleCookiesModal()}
        >
          นโยบายคุ้มครองข้อมูลส่วนบุคคล
        </Button>
      </CookieConsent>
      <Modal
        isOpen={modal2}
        toggle={toggleModal2}
        className="modal-dialog modal-md"
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={toggleModal2}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-left" id="exampleModalLabel">
            คลิกที่ QRCode เพื่อดาวน์โหลด
          </h5>
        </div>
        <div className="modal-body text-center">
          <QRCode
            id="qr-gen-download"
            value={qrValue}
            size={250}
            level={"H"}
            includeMargin={true}
            className="mr-3 mt-2"
            onClick={downloadQRCode}
          />
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-dialog modal-lg"
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-left" id="exampleModalLabel">
            {caption}
          </h5>
        </div>
        <div className="modal-body text-center">
          <img alt="" className="img-rounded img-responsive" src={thumbnail} />
          <div
            className="mt-4 text-left"
            dangerouslySetInnerHTML={{ __html: detail }}
          ></div>
        </div>
        <div className="modal-footer">
          <div className="modal-body text-left">
            <div>
              <QRCode
                className="mr-2"
                id="qr-gen"
                value={qrValue}
                size={59}
                level={"H"}
                includeMargin={true}
                onClick={toggleModal2}
              />
            </div>
            <div>
              <CopyToClipboard
                text={qrValue}
                onCopy={() => settxtToclipboard(true)}
                className="mt-0"
              >
                <span>{qrValue}</span>
              </CopyToClipboard>
              {txtToclipboard ? (
                <span style={{ color: "red" }}> Copied.</span>
              ) : null}
            </div>
          </div>
          <div>
            <Button
              className="btn-link"
              color="info"
              type="button"
              href={file}
              target="_blank"
            >
              <h4>
                <i className="nc-icon nc-cloud-download-93" />
                <span className="ml-3">เปิดเอกสาร</span>
              </h4>
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={cookiesModal}
        toggle={toggleCookiesModal}
        className="modal-dialog modal-lg"
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={toggleCookiesModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-left" id="exampleModalLabel">
            นโยบายคุ้มครองข้อมูลส่วนบุคคล
          </h5>
        </div>
        <div className="modal-body text-left">
          <h3>
            เว็บไซต์ www.su-plan.org และ www.plan.su.ac.th และระบบอื่น ๆ
            ภายใต้โดเมนดังกล่าว
          </h3>
          <p className="mt-4">
            {"   "} กองแผนงาน.... มหาวิทยาลัยศิลปากร
            ในฐานะผู้พัฒนาและบริหารจัดการ www.su-plan.org และ www.plan.su.ac.th
            (“เว็บไซต์”) ให้ความเชื่อมั่นแก่ท่านว่า
            ข้อมูลส่วนบุคคลทั้งหมดของท่านเป็น ข้อมูลที่ทางกองแผนงานให้ความ สำคัญ
            โดยกองแผนงานรับประกันจะปกป้องและประมวลผลข้อมูลส่วบบุคคลด้วยมาตรการรักษาความมั่นคงปลอด
            ภัยที่เหมาะสมอย่างดีที่สุด
            และข้อมูลทั้งหมดของท่านจะถูกเก็บรักษาเป็นความลับ{" "}
          </p>
          <p className="mt-4">
            เมื่อท่าน ในฐานะผู้ใช้บริการ ไม่ว่าในนามส่วนบุคคลหรือในนามนิติบุคคล
            เข้าถึงเว็บไซต์ ติดต่อ และ/หรือ ใช้บริการของทางกองแผนงาน
            รวมถึงกรณีที่ท่านส่งข้อมูลส่วนบุคคลใดๆของท่าน ให้แก่กองแผนงาน
            หรือให้ความยิน ยอมใดๆแก่กองแผนงาน
            ภายใต้เงื่อนไขและข้อกำหนดที่ระบุไว้ในนโนยบายข้อมูลส่วนบุคคลฉบับนี้
            กองแผนงานจะถือว่า
            ท่านตกลงและยอมรับที่จะปฏิบัติตามนโยบายข้อมูลส่วนบุคคลฉบับนี้แล้ว
            ทั้งนี้ นโยบายข้อมูลส่วนบุคคล ฉบับนี้มีจุดประสงค์เพิ่มเติม
            โดยไม่มีผลเปลี่ยนแทนหรือทดแทนการให้ความยินยอมใดๆ ซึ่งท่านอาจ
            เคยได้ให้แก่ กองแผนงานในส่วนที่เกี่ยวข้องกับ ข้อมูลส่วนบุคคลของท่าน
          </p>
          <p className="mt-4">
            กรุณาศึกษานโยบายข้อมูลส่วนบุคคล
            พร้อมกับเงื่อนไขการให้บริการของกองแผนงานอย่างละเอียด หากท่านไม่
            ตกลงให้ทางกองแผนงานดำเนินการประมวลผลข้อมูลส่วนบุคคลของท่าน
            ตามที่ระบุไว้นี้ กองแผนงานสงวนสิทธิที่จะ ปฏิเสธการให้บริการใดๆ
            แก่ท่าน เนื่องจากการประมวลผลข้อมูลส่วนบุคคลภายใต้นโยบายฉบับนี้มีความ
            จำเป็นอย่างยิ่งต่อการปฏิบัติหน้าที่ในการให้บริการโดยกองแผนงานแก่ท่าน
          </p>
          <p className="mt-4">
            นโยบายความเป็นส่วนตัวฉบับนี้ มีผลใช้บังคับกับเว็บไซต์ การติดต่อ
            และ/หรือการบริการของกองแผนงานเท่านั้น
            ไม่มีผลใช้บังคับกับแอปพลิเคชั่น บริการ หรือเว็บไซต์อื่นๆ
            ที่เชื่อมต่อกับเว็บไซต์ของกองแผนงาน ซึ่งเป็นของบุคคล
            ภายนอกและกองแผนงานไม่มีอำนาจควบคุม
            ซึ่งเป็นส่วนที่ผู้ใช้บริการต้องทำความตกลง และศึกษาเกี่ยวกับ
            นโยบายข้อมูลส่วน บุคคล
            ที่บังคับใช้และประกาศโดยบุคคลภายนอกดังกล่าวแยกต่างหากทางกองแผนงานอาจปรับปรุงนโยบายฉบับนี้ตามแต่ละระยะเวลา
            เพื่อให้สอดคล้องกับแนวปฏิบัติและกฎหมายที่ เกี่ยวข้อง
            และให้สอดคล้องกับการให้บริการต่างๆของกองแผนงาน ทั้งนี้
            กองแผนงานจะแจ้งให้ท่านทราบถึงการเปลี่ยน แปลง
            ด้วยการประกาศนโยบายฉบับปรับปรุงใหม่ผ่านทางเว็บไซต์
          </p>
          <p className="mt-4">
            ลักษณะข้อมูลส่วนบุคคลที่กองแผนงานประมวลผลในการให้บริการและติดต่อกับท่าน
            การประมวลผลข้อมูลส่วนบุคคล
            ที่ระบุไว้ภายใต้เงื่อนไขนโยบายข้อมูลส่วนบุคคลฉบับนี้ มีความจำเป็น
            สำหรับการดำเนินกระบวนการทางธุรกิจของกองแผนงาน
            โดยเฉพาะส่วนที่เกี่ยวข้องกับการให้บริการโดยตรงแก่
            ท่านในฐานะผู้ใช้บริการเว็บไซต์ ไม่ว่าจะเป็นการส่งหนังสือบอกกล่าว
            การติดต่อสื่อสารตอบกลับการขอ ข้อมูลใบเสนอราคาของท่าน
            การบริหารความสัมพันธ์กับท่านในฐานะลูกค้า การนำเสนอบริการใหม่ หรือการ
            จัดกิจกรรมต่างๆ รวมถึง
            กระบวนการในการวางแผนเกี่ยวกับบริการใหม่ในอนาคตของกองแผนงาน ทั้งนี้
            ข้อมูล ส่วนบุคคลของท่านที่กองแผนงานมี ความจำเป็นต้องประมวลผลข้อมูล
            มีดังนี้
          </p>
          <p className="mt-4">
            <ul class="list-group ml-4 mt-2">
              <li>
                ข้อมูลทั่วไปเกี่ยวกับท่านและการติดต่อ อาทิ ชื่อ นามสกุล ที่อยู่
                สถานที่ติดต่อ เบอร์โทรศัพท์ อีเมล{" "}
              </li>
              <li>
                {" "}
                ข้อมูลการใช้บริการของท่าน เช่น
                ประวัติการทำธุรกรรมการใช้บริการของท่าน รายละเอียดบริการที่
                ท่านใช้บริการจากกองแผนงาน ประวัติการทำธุรกรรมอื่นๆ
                ของท่านผ่านเว็บไซต์ รวมถึงข้อมูลการชำระเงินที่ท่านชำระให้
                แก่กองแผนงาน
              </li>
              <li>
                ข้อมูลทางเทคนิคในการระบุตัวตน อาทิ หมายเลขรหัสนักศึกษา
                รหัสพนักงาน หมายเลขระบุตำแหน่งคอมพิวเตอร์ (IP Address)
                การตั้งค่าและการเชื่อมต่อบราวเซอร์ของอุปกรณ์ที่ผู้ใช้บริการ
                ใช้เชื่อมต่อกับระบบของกองแผนงาน และอาจรวมถึงการใช้ Cookies ต่างๆ
                เพื่อการติดตามพฤติกรรมการใช้งาน ของท่าน •
              </li>
              <li>
                ข้อมูลอื่นที่ท่านอาจให้
                เกี่ยวกับลักษณะการทำการตลาดที่ท่านต้องการ
                หรือข้อมูลที่ท่านให้เพื่อการ เข้าร่วมกิจกรรมส่งเสริมการขาย
                การสำรวจ การแข่งขัน การชิงรางวัล หรือกิจกรรมส่งเสริมการขายลักษณะ
                ใดๆ กับกองแผนงาน
              </li>
            </ul>
          </p>
          <p className="mt-4">
            ข้อมูลส่วนบุคคลอื่นๆที่ท่านอาจให้แก่กองแผนงาน
            ระหว่างการติดต่อสื่อสารเพื่อการให้บริการแก่ท่านหรือ
            ระหว่างการติดต่อเพื่อการให้บริการหลังการขายต่างๆ
            หรือข้อมูลส่วนบุคคลที่ท่านอาจให้ยินยอมแก่กองแผนงาน ในการประ
            มวลผลข้อมูลส่วนบุคคลดังกล่าว
            กองแผนงานอาจเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน เมื่อ:
          </p>{" "}
          <ul class="list-group ml-4 mt-4">
            <li>
              ท่านติดต่อกับกองแผนงาน (เช่น เมื่อท่านติดต่อเพื่อสอบถามข้อมูล
              ขอใบเสนอราคา ด้วยวิธีการประชุม แบบพบหน้า ทางหน้าเว็บไซต์
              ทางโทรศัพท์ และทางอีเมล){" "}
            </li>
            <li>
              ท่าน ในนามส่วนบุคคล หรือในนามของนิติบุคคลใด
              กรอกเข้าในแบบฟอร์มสำหรับการสร้างเอก สารประเภทต่างๆ
              ผ่านทางเว็บไซต์ของกองแผนงาน ซึ่งรวมถึงแต่ไม่จำกัดเพียง
              ข้อมูลการติดต่อท่าน ในฐานะ ผู้ประมวลผลข้อมูลส่วนบุคคล
              หรือข้อมูลรายละเอียดอื่นๆ
              ซึ่งอาจมีการกรอกเข้าระบบเพื่อการสร้างเอกสาร ดังกล่าวนั้น
              และข้อมูลอีเมลการติดต่อสำหรับการส่งเอกสารที่เกี่ยวข้องซึ่งสร้างจากเว็บไซต์ของกองแผนงาน
              ให้ แก่ท่านในฐานะผู้ใช้บริการ
            </li>{" "}
            <li>
              ท่านลงทะเบียนความสนใจ และให้ความสนใจสมัครรับข้อมูลข่าวสาร
              เกี่ยวกับบริการ รวมถึง การ สื่อสารประชาสัมพันธ์อื่นๆ จากกองแผนงาน
              (เช่น หนังสือพิมพ์ข่าว){" "}
            </li>{" "}
            <li>
              {" "}
              ท่านเข้าร่วมการทำแบบสำรวจ การแข่งขัน การชิงรางวัล
              หรือกิจกรรมส่งเสริมการขายอื่น
            </li>{" "}
            <li>
              ท่านให้ความคิดเห็นเกี่ยวกับการทำงาน และให้บริการของกองแผนงาน (เช่น
              การแสดงความคิดเห็นผ่าน
              ทางเว็บไซต์ของกองแผนงานหรือเป็นกระดาษแบบฟอร์ม) และ/หรือ
              ท่านส่งข้อมูลส่วนบุคคลให้แก่กองแผนงาน ด้วยเหตุผลอื่น
            </li>
          </ul>
          <p className="mt-4">
            นอกเหนือจากการได้รับข้อมูลส่วนบุคคลโดยตรงจากท่าน
            (ตามที่ระบุไว้ข้างต้น)แล้ว กองแผนงานอาจได้รับข้อมูล
            ส่วนบุคคลของท่านจากบุคคลอื่น ที่ท่านอาจได้ติดต่อ
            และให้ความยินยอมในการเปิดเผยข้อมูลส่วนบุคคล ของท่านให้แก่ กองแผนงาน
            จุดประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
            ข้อมูลส่วนบุคคลที่ท่านให้แก่กองแผนงาน จะได้รับการเก็บ
            และใช้เพื่อจุดประสงค์ต่างๆ ซึ่งรวมถึงจุดประสงค์ ดังต่อไปนี้:
          </p>{" "}
          <p className="mt-4">
            <ul class="list-group ml-4 mt-4">
              <li>
                การส่งหนังสือบอกกล่าว
                การติดต่อกลับเพื่อนำเสนอข้อมูลใบเสนอราคาที่ท่านร้องขอ
                การให้บริการ
                แก่ท่านในการสร้างเอกสารเพื่อการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลให้แก่ท่าน
                รวมถึง การให้บริการฝึกอบรม
                หรือการให้บริการสนับสนุนท่านผู้ใช้บริการ
                ในลักษณะอื่นที่เกี่ยวข้อง
              </li>
              <li>
                ในกรณีที่ได้รับความยินยอมจากท่าน การส่งข้อมูลให้แก่ท่าน
                ผ่านทางอีเมล หรือช่องทางการติดต่อ อื่น
                หรือการติดต่อผ่านทางช่องทางโซเชียลมีเดียใดๆ
                เกี่ยวกับบริการของกองแผนงาน ซึ่งตรงกับความสนใจของท่าน{" "}
              </li>
              <li>
                การสร้าง และปรับปรุงความสัมพันธ์ที่กองแผนงานมีกับท่าน
                ซึ่งอาจรวมถึงการฝึกอบรมพนักงาน และการควบคุมรับประกันคุณภาพ
                โดยเฉพาะส่วนของพนักงานที่รับผิดชอบประสานงานกับลูกค้า ผ่านช่อง
                ทางการติดต่อต่างๆ รวมถึงการวิเคราะห์ สอบสวน
                จัดการและแก้ไขปัญหาต่างๆ ที่เกี่ยวข้องกับการให้ บริการ ทั้งนี้
                กองแผนงานจะประเมินถึงความจำเป็นในการใช้ข้อมูลเพื่อจุดประสงค์ดังกล่าวเพื่อความจำเป็นเท่านั้น
              </li>{" "}
              <li>
                {" "}
                การจัดการตอบรับการสื่อสารที่ท่านติดต่อพวกเรา (เช่น
                เพื่อการตอบคำถาม หรือการจัดการข้อร้อง เรียนการใช้บริการ
                หรือการให้ความคิดเห็นต่างๆ){" "}
              </li>
              <li>
                การแจ้งให้ท่านทราบเกี่ยวกับ
                การเปลี่ยนแปลงในส่วนของลักษณะรูปแบบการทำงานของบริการ
                ของกองแผนงาน เช่น ข้อมูลการปรับปรุงเวอร์ชั่นของเอกสารต่างๆ
                ซึ่งเป็นบริการของกองแผนงาน{" "}
              </li>
              <li>
                การวิเคราะห์ความสนใจของท่าน
                เพื่อใช้ในการที่กองแผนงานจะปรับปรุงรูปแบบการให้บริการ โดย
                เฉพาะการออกแบบ กระบวนการกลไกการวิเคราะห์และทดสอบต่างๆ
                ให้สามารถตอบสนองความต้องการ และความสนใจของท่าน ได้มากขึ้น
                หรือเพื่อการสร้างความสัมพันธ์ที่ดีขึ้นระหว่างกองแผนงานและท่าน
              </li>{" "}
              <li>
                การป้องกันและตรวจสอบการกระทำที่อาจผิดกฎหมายหรือผิดเงื่อนไขการใช้บริการของกองแผนงาน
                เช่น การป้องกัน
                และการปกป้องสิทธิกรณีการละเมิดทรัพย์สินทางปัญญาของกองแผนงาน
              </li>{" "}
              <li>
                การเข้าถึงหรือเข้าร่วมในการแข่งขัน กิจกรรมส่งเสริมการขายต่างๆ
                ที่กองแผนงานจัดขึ้น ซึ่งท่านอาจตกลง เข้าร่วม
                ภายใต้เงื่อนไขที่กำหนดไว้ •
                การปฏิบัติหน้าที่ตามกฎหมายที่กองแผนงานอาจมี และต้องปฏิบัติตาม
                เช่น หน้าที่ในการจัดทำบัญชี และ ชำระภาษี
                หรือหน้าที่ภายใต้พระราชบัญญัติเกี่ยวกับคอมพิวเตอร์หรือการให้บริการอิเล็กทรอนิกส์ต่างๆ
              </li>
              <li>
                จุดประสงค์อื่นใดที่พิจารณาได้ว่ามีความเกี่ยวข้องกับจุดประสงค์ที่ระบุไว้
              </li>
            </ul>
          </p>
          <p className="mt-4">
            กองแผนงานมีความจำเป็นต้องประมวลผล
            และเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน เพื่อจุดประสงค์ต่างๆ ที่ระบุ
            ไว้ข้างต้น
            ตลอดระยะเวลาตราบเท่าที่กองแผนงานยังมีหน้าที่ตามสัญญาการให้บริการหรือตามกฎหมายต่อท่าน
            และอาจต่อเนื่องไปภายหลังการสิ้นสุดหน้าที่ตามสัญญา
            หากเป็นส่วนที่กองแผนงานอาจมีความจำเป็นต้องเก็บรวบ รวมเพื่อการต่อสู้
            หรือปกป้องสิทธิที่กองแผนงานอาจมีต่อท่าน
            โดยเฉพาะส่วนของการป้องกันการกระทำผิด กฎหมาย
            เช่นการละเมิดทรัพย์สินทางปัญญาของกองแผนงาน
            หรือการกระทำผิดเงื่อนไขการใช้บริการของกองแผนงาน
            โดยเก็บต่อเนื่องเป็นระยะเวลาที่
            เหมาะสมและจำเป็นในการดำเนินการดังกล่าว และสำหรับการประมวลผล
            ข้อมูลด้วยความยินยอมที่ท่านได้ให้แก่กองแผนงาน
            กองแผนงานจะประมวลผลข้อมูลส่วนบุคคลของท่านจนกว่า ท่าน
            จะใช้สิทธิในการยกเลิกความยินยอมที่ท่านได้ให้ แก่กองแผนงาน ทั้งนี้
            การที่ท่านยังคงใช้บริการของกองแผนงานอยู่ กองแผนงาน
            จะถือว่าท่านยอมรับนโยบายฉบับนี้เสมอ
          </p>
          <h4>การเปิดเผยข้อมูลส่วนบุคคล</h4>
          <p>
            โดยหลักการแล้วข้อมูลส่วนบุคคลที่ท่านให้แก่กองแผนงานจะได้รับการเก็บเป็นความลับ
            แต่ในบางกรณีข้อมูลส่วน
            บุคคลของท่านอาจมีความจำเป็นต้องได้รับการเปิดเผยให้แก่บุคคล ดังนี้
            เพื่อการปฏิบัติหน้าที่ตามสัญญา
            หรือตามกฎหมายที่กองแผนงานอาจมีให้แก่ท่าน:
          </p>{" "}
          <p className="mt-4">
            <ul class="list-group ml-4 mt-4">
              <li>
                เปิดเผยให้แก่ ผู้ให้บริการภายนอกของกองแผนงาน
                (ซึ่งรวมถึงแต่ไม่จำกัดเพียง กองแผนงานพันธมิตรธุรกิจ)
                ที่ให้การช่วยเหลือสนับสนุนกองแผนงานในการให้บริการแก่ท่าน เช่น
                ที่ปรึกษา ผู้ให้บริการฝึกอบรม รวมถึง ผู้ให้
                บริการภายนอกที่ให้บริการประเมินการทำงานของเว็บไซต์
                โดยกองแผนงานจะดำเนินการส่งต่อและเปิดเผยข้อมูล ส่วนบุคคลของท่าน
                ตามขอบวัตถุประสงค์ที่ระบุไว้เท่านั้น
                และบนพื้นฐานเท่าที่จำเป็นเท่านั้น
              </li>{" "}
              <li>
                ข้อมูลต่างๆในการให้บริการของกองแผนงาน
                ซึ่งรวมถึงข้อมูลส่วนบุคคลของผู้ใช้บริการอาจได้รับการเก็บ
                รักษาไว้ใน ระบบ Cloud (ซึ่งอาจเป็นกองแผนงานภายในหรือต่างประเทศ)
                ทั้งนี้ในการใช้บริการดังกล่าว กองแผนงานรับประกัน
                การพิจารณาตั้งค่าการรักษาความมั่นคงปลอดภัยและความลับของข้อมูลส่วนบุคคลของ
                ผู้ใช้บริการ เป็นระดับ พิเศษด้วยมาตรฐานที่เหมาะสมกับความเสี่ยง{" "}
              </li>{" "}
              <li>
                กองแผนงานอาจมีความจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่าน
                เพื่อความจำเป็นในการปกป้อง และ ต่อสู้สิทธิใดๆของกองแผนงาน
                หรือเพื่อการป้องกันและตรวจสอบลักษณะการกระทำผิดที่เกี่ยวข้องกับการใช้บริการ
                ของท่านซึ่งอาจกระทบสิทธิของบุคคลอื่น
                โดยกองแผนงานรับประกันจะดำเนินการดังกล่าวเท่าที่จำเป็น ด้วยจุดประ
                สงค์ดังกล่าวเท่านั้น
              </li>{" "}
              <li>
                ในกรณีที่กองแผนงานมีหน้าที่ตามกฎหมาย
                หรืออยู่ภายใต้บังคับคำพิพากษา หรือคำคำสั่งของกองแผนงาน ราชการ
                กองแผนงานอาจมีความจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการให้แก่
                กองแผนงานดังกล่าว เพื่อ เป็นการปฏิบัติตามหน้าที่ที่มีตามกฎหมาย
                โดยกองแผนงานจะดำเนินการ เพียงเท่าที่จำเป็นตามหน้าที่ดังกล่าว
                เท่านั้น
              </li>{" "}
              <li>
                Cookies ที่กองแผนงานใช้ในการให้บริการแก่ท่าน • Cookies คือ text
                files ที่อยู่ใน Browser ของผู้ใช้บริการ
                ใช้เพื่อจัดเก็บรายละเอียดข้อมูล บันทึกการใช้งาน อินเตอร์เน็ต
                หรือพฤติกรรมการเยี่ยมชมเว็บไซต์ของผู้ใช้บริการ ทั้งนี้
                เพื่อรับประกันประสิทธิภาพในการ
                ทำงานให้บริการของเว็บไซต์แก่ผู้ใช้บริการ
                กองแผนงานมีความจำเป็นต้องใช้คุกกี้หลายประเภทเพื่อจุดประสงค์
                ต่างกันไป โดย แบ่งออกเป็น 2 ประเภท ต่อไปนี้{" "}
              </li>
              <li>
                Strictly Necessary Cookies
                เป็นคุกกี้ประเภทที่มีความจำเป็นอย่างมากต่อการทำงานของเว็บไซต์
                และการ ให้บริการแก่ผู้ใช้งาน
                ในการเข้าถึงข้อมูลได้อย่างทั่วถึงและปลอดภัย{" "}
              </li>
              <li>
                Other Cookies ซึ่งอาจรวมถึงคุกกี้ประเภท Functionality Cookies
                ที่ทำหน้าที่จดจำสิ่งที่ผู้ใช้ บริการเลือกหรือตั้งค่าบนแพลตฟอร์ม
                เช่น ชื่อบัญชีผู้ใช้ ภาษา ฟ้อนต์ และรูปแบบแพลตฟอร์ม เพื่อ
                การนำเสนอข้อมูล ที่ตรงความต้องการเฉพาะบุคคล
                ให้แก่ผู้ใช้บริการได้มากขึ้น ตามการตั้งค่าต่างที่เลือกไว้ หรือ
                คุกกี้ประเภท Performance Cookies
                ที่ทำหน้าที่ประเมินประสิทธิภาพในการทำงานแต่ละส่วนของ เว็บไซต์
                และคุกกี้ประเภท Advertising Cookies
                ที่ทำหน้าที่ในการจดจำสิ่งที่ท่านเคยเยี่ยมชม รวมถึง
                ลักษณะการใช้เว็บไซต์ ของผู้ใช้บริการ
                เพื่อนำเสนอบริการที่เกี่ยวข้องและตรงกับความสนใจของผู้ใช้บริการ
              </li>{" "}
              <li>
                สำหรับคุกกี้ประเภท Strictly Necessary Cookies นั้น
                กองแผนงานขอชี้แจงให้ท่านผู้ใช้บริการทราบว่า ทาง
                กองแผนงานมีความจำเป็นในการใช้คุกกี้ดังกล่าว
                เพื่อการให้บริการของเว็บไซต์ได้อย่างเต็มประสิทธิภาพ หาก
                กองแผนงานไม่ใช้คุกกี้ประเภทดังกล่าว
                กองแผนงานย่อมไม่สามารถให้บริการแก่ท่านผู้ใช้บริการได้
                และในส่วนของคุกกี้ ประเภทอื่นนั้น
                หากได้รับความยินยอมจากท่านในการอนุญาตให้กองแผนงานใช้คุกกี้ดังกล่าว
                กองแผนงานจะใช้คุกกี้ ดังกล่าวทั้งหมด เพื่อจุดประสงค์
                เฉพาะที่ได้ระบุไว้สำหรับแต่ละประเภทคุกกี้ แม้ว่า
                การใช้คุกกี้จะมีประโยชน์ในการเสริมประสิทธิภาพในการให้บริการ
                และการทำงานของเว็บไซต์แก่ ท่านผู้ใช้บริการ
                แต่หากผู้ใช้บริการต้องการ ท่านสามารถลบการตั้งค่าคุกกี้บน Browser
                ของตนเองได้ อย่างไรก็ตาม ผู้ใช้บริการต้องรับทราบว่า
                การดำเนินการดังกล่าว อาจส่งผลกระทบต่อประสิทธิภาพ
                ในการทำงานให้บริการของเว็บไซต์ได้ในบางส่วน การทำการตลาดทางตรง{" "}
              </li>
              <li>
                หากท่านได้ให้ความยินยอมแก่กองแผนงานแล้ว
                กองแผนงานอาจใช้ข้อมูลส่วนบุคคลของท่าน เพื่อให้ข้อมูลเกี่ยวกับ
                บริการอื่นๆของกองแผนงาน และ/หรือของกองแผนงานในเครือ
                หรือพันธมิตรทางธุรกิจของกองแผนงาน ซึ่ง อาจเป็นที่น่าสนใจ
                หรือเป็นประโยชน์แก่ท่านได้ เว้นแต่
                ท่านใช้สิทธิในการถอนความยินยอมดังกล่าว
                คำรับประกันการดำเนินมาตรการรักษาความมั่นคงปลอดภัยในข้อมูลที่เหมาะสม
              </li>
              <li>
                กองแผนงานรับประกันจัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม
                เพื่อป้องกัน การเข้าถึง การใช้ การ เปลี่ยนแปลง การแก้ไข
                หรือการเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
                นอกจากนี้ กองแผนงานได้ กำหนดแนวปฏิบัติภายใน
                เพื่อกำหนดสิทธิในการเข้าถึงหรือการใช้ข้อมูลส่วนบุคคล
                ของเจ้าของข้อมูลส่วนบุคคล
                เพื่อรักษาความลับและความปลอดภัยของข้อมูล ทั้งนี้
                กองแผนงานจะจัดให้มีการ ทบทวนมาตรการดังกล่าวเป็นระยะ
                เพื่อความเหมาะสม ตามมาตรฐานในอุตสาหกรรม และโดย
                สอดคล้องกับกฎหมายที่เกี่ยวข้อง
              </li>
            </ul>
          </p>
          <h4>สิทธิของเจ้าของข้อมูล </h4>
          <p className="mt-4">
            กองแผนงานรับทราบ และเคารพสิทธิตามกฎหมายของผู้ใช้บริการ
            ในส่วนที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่าน ที่อยู่
            ในการควบคุมของกองแผนงานได้ ตามเงื่อนไขกำหนดสิทธิที่ระบุไว้ในกฎหมาย
            ดังนี้{" "}
          </p>
          <ul class="list-group ml-4 mt-4">
            <li>
              สิทธิขอเข้าถึง และขอรับสำเนาข้อมูลส่วนบุคคล
              รวมถึงสิทธิในการขอแก้ไขข้อมูลส่วนบุคคลให้เป็น ปัจจุบัน และถูกต้อง{" "}
            </li>
            <li>
              สิทธิขอรับข้อมูลส่วนบุคคล
              ในกรณีที่กองแผนงานทำให้ข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบที่สามารถอ่าน
              หรือใช้งาน
              โดยทั่วไปด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติ รวมถึง
              สิทธิขอให้ส่งหรือโอนข้อ
              มูลรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
            </li>{" "}
            <li>สิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคล </li>
            <li>
              สิทธิขอให้ลบหรือทำลาย
              หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้
              เมื่อ ข้อมูลนั้น หมดความจำเป็นหรือเมื่อมีการถอนความยินยอม{" "}
            </li>
            <li>
              สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลได้
              ในกรณีเมื่อเป็นข้อมูลส่วนบุคคลที่ต้องลบ หรือ เมื่อข้อมูล
              ดังกล่าวหมดความจำเป็น{" "}
            </li>{" "}
            <li>
              {" "}
              สิทธิถอนความยินยอม
              ในการประมวลผลข้อมูลที่ผู้ใช้บริการเคยให้ไว้ตามวัตถุประสงค์ที่ระบุไว้
            </li>
          </ul>
          <p className="mt-4">
            ผู้ใช้บริการสามารถติดต่อมายังกองแผนงาน
            เพื่อดำเนินการขอใช้สิทธิข้างต้นได้ ตามรายละเอียดการติดต่อที่
            กองแผนงานได้ กำหนดไว้
            โดยกองแผนงานจะพิจารณาและแจ้งผลการพิจารณาคำร้องของท่านให้ทราบภายใน 30
            วันนับแต่วันที่ได้รับ คำร้องขอดังกล่าว
          </p>{" "}
          <h4>ช่องทางการติดต่อผู้ควบคุมข้อมูลและดูแลเว็บไซต์</h4>
          นายศิลา สังข์จุ้ย กองแผนงาน มหาวิทยาลัยศิลปากร สถานที่ติดต่อ: 22
          ถนนบรมราชชนนี ตลิ่งชัน กรุงเทพฯ อีเมล: sungjuic_w@su.ac.th โทร. 0 2105
          4686 ต่อ 100095
        </div>
        <div className="modal-footer">
          <div>
            <Button
              className="btn-link"
              color="info"
              type="button"
              onClick={() => toggleCookiesModal()}
            >
              <h4>
                <span className="ml-3">ปิด</span>
              </h4>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LandingPage;
